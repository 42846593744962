

function Cover() {
    return (
      <>
         {/* cover section  */}
            <section className="hero-section md:bg-pink-300 claims-hero">
            <div className="container">
                <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                    <div>
                        <h1>
                            We pay claims in <br />
                            48hours, or less
                        </h1>
                        <p className="text-lg md:text-xl mr-0 md:mr-24">You won’t have to wait weeks to get your claims paid. Here, it’s a simple, fast and enjoyable claims process</p>
                        <a href="https://forms.gle/Wi9Zf2ocHkz4JLAQA" target="_blank" className="button button--white mt-5"><span>Make a claim</span></a>
                    </div>
                </div>
            </div>
            </section>

      </>
    );
  }
  
  export default Cover;
  