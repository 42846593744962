import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer";
import Cover from "../components/BusinessGro/Cover";
import BusinessFaq from "../components/Common/BusinessFaq";
import Banner from "../components/Common/Banner";
import smedan_casava from "../assets/smedan_casava.svg";
import business_security from "../assets/Business-security.svg";
import business_gro_phone from "../assets/business-gro-phone.svg";
import smedan from "../assets/smedan.png";
import small_business from "../assets/small_business_image.png";
import faqData from "../config/faq.json";
import brand_logo_1 from "../assets/brand-logo-01.png";
import brand_logo_2 from "../assets/brand-logo-02.png";
import brand_logo_3 from "../assets/brand-logo-03.png";
import brand_logo_4 from "../assets/brand-logo-04.png";
import brand_logo_5 from "../assets/brand-logo-05.png";
import brand_logo_6 from "../assets/brand-logo-06.png";
import arrow_down from "../assets/arrow-down-right.svg";
import FaqComponent from 'react-faq-component';
function BusinessGro() {

let smedanUrl;
let faqUrl;

if (process.env.NODE_ENV === 'development') {
  smedanUrl = "https://health.dev.smedanregister.ng/";
} else if (process.env.NODE_ENV === 'production') {
    smedanUrl = "https://health.smedanregister.ng/";
    faqUrl = "https://faq.dev.casava.com/";
}else if (process.env.NODE_ENV === 'test') {
    smedanUrl = "https://health.staging.smedanregister.ng/";
}

  let index = Math.ceil(faqData["businessGroData"]["rows"].length / 2);

  let rows = faqData["businessGroData"].rows;
  let data1 = { title: "", rows: [] };
  data1.rows = rows.slice(0, index);

  let data2 = { title: "", rows: [] };
  data2.rows = rows.slice(index, faqData["businessGroData"]["rows"].length);

  let businessGrorows = faqData["businessGro"].rows;
  let data3 = {title:"", rows:[]}
  data3.rows = businessGrorows.slice(0,1)

  let healthPerksInfo = [
    {
      heading: "Get Insurance Always",
      desc: "Get Insurance and access to loans from providers",
    },
    {
      heading: "Private Sector Benefits",
      desc: "Trainings and educational content by professionals,",
    },
    {
      heading: "Tools for Growth",
      desc: "Scale your businesses by getting the right tools for faster growth.",
    },
    {
      heading: "Grant Loans and More",
      desc: "Get business support from the government ",
    },
  ];
  return (
    <div>
      <Header></Header>
      <section className="dark-green--background no-padding-bottom">
        <Cover></Cover>

        <section className="fature-section no-padding">
          <div className="container modifying-container dark-blue--background">
            <div className="p-[10px] grid gap-10 grid-rows-2 lg:grid-rows-2 lg:gap-20 items-center">
              <img src={business_security} alt="business security image" />
              <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-10 items-center">
                <div className="business-gro-box1 flex flex-col black--background ">
                  <h1 className="mb-6">
                    About <br /> Business Gro{" "}
                  </h1>
                  <p className="text-xl">
                    With Business Gro, you have access to capacity building and
                    relevant training that allows you to enjoy basic business
                    continuity and financial protection whenever you encounter
                    natural or unexpected events 
                  </p>
                  <a
                    href="https://smedanregister.ng/"
                    target="_blank"
                    className="button button--pink mt-10 self-start"
                  >
                    <span>Get started</span>
                  </a>
                </div>
                <div className="image2">
                  <img
                    src={business_gro_phone}
                    alt="casava ai claims illustration image"
                    className="business-gro-phone "
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="fature-section no-padding">
          <div className="container modifying-container white--background ">
            <div className="grid grid-cols-1 items-center">
              <div className="text-center order-first mb-6">
                <div className="flex justify-center mb-10">
                  <img src={smedan_casava} alt="smedan casava" />
                </div>
                <h1 className="mb-6">
                  Insurance <br /> starts here.
                </h1>
                <p className="text-xl font-normal">
                  If you have an idea, a big heart, and you love <br />{" "}
                  creating, then our barn is wide open. Come in.
                </p>
                <a
                  href="https://smedanregister.ng/"
                // href= {smedanUrl}
                  target="_blank"
                  className="button button--pink mt-10 self-start"
                >
                  <span>Get started</span>
                </a>
              </div>
              <div className="image-layout order-first">
                <img src={smedan} alt="Screenshot of the smedan webapp" />
              </div>

              <div className="faq-component">
                       <FaqComponent
                            data={data3}
                        /> 
                        <span className="flex">
                        <a href={faqUrl} className="faq-link">Read more on FAQ</a> 
                        <img src={arrow_down}/>
                        </span>
                        </div>
                        </div>
          </div>
        </section>

        <section className="fature-section no-padding">
          <div className="container modifying-container white--background">
            <div className=" items-center">
              <div className="text-center order-first ">
                <h1 className="mb-10">
                  Enjoy amazing <br /> benefits
                </h1>
                <div className="cards-container grid grid-cols-1 gap-10 lg:grid-cols-2 md:grid-cols-1 md:gap-10 items-center justify-center">
                  {healthPerksInfo.map((element, index) => {
                    return (
                      <div
                        key={index}
                        className=" card light-gray--background"
                      >
                        <p className="cardheading mb-5">{element.heading}</p>
                        <h5 className="carddesc">{element.desc}</h5>
                      </div>
                    );
                  })}
                </div>
                <a
                  // href={smedanUrl}
                  href="https://smedanregister.ng/"
                  target="_blank"
                  className="button button--pink mt-10 self-start"
                >
                  <span>Get started</span>
                </a>
              </div>
            </div>
          </div>
        </section>


          <div className="contain">

              <img src={small_business} alt="small business image"/>
          </div>

      </section>

      <section>
        <div className="brands-section">
          <div className="container">
            <div className="partners text-center order-first">
              <h1 className="mb-6 text-2xl ">Other initiative partners</h1>
              <div className="grid gap-10 grid-cols-1 lg:grid-cols-3 md:grid-cols-2 md:gap-20 items-center justify-center">
                <div>
                  <img src={brand_logo_5} alt="" />
                </div>
                <div>
                  <img src={brand_logo_3} alt="" />
                </div>
                <div>
                  <img src={brand_logo_6} alt="" />
                </div>
                <div>
                  <img src={brand_logo_2} alt="" />
                </div>
                <div>
                  <img src={brand_logo_1} alt="" />
                </div>
                <div>
                  <img src={brand_logo_4} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BusinessFaq data1={data1} data2={data2}></BusinessFaq>
      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default BusinessGro;
