
function Cover() {
    return (
      <>
  
        {/* cover section  */}
        <section className="hero-section md:bg-pink-300 credit-life-page">
        <div className="container">
          <div className="pill-item bg-light-green-400">Credit Life</div>
            <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-1 md:gap-20">
                <div>
                    <h1>
                      Building sustainable <br /> businesses with <br /> Credit life
                    </h1>
                    <p className="text-lg md:text-xl mr-0 md:mr-10">Credit life insurance covers your business from the <br /> financial burden of sudden tragedy faced by <br /> borrowers. We’ll pay you back what they owe.</p>
                    <a href="https://forms.gle/gQ1WFYJr9cRXqYVR7" target="_blank" className="button button--white mt-6"><span>Get in touch</span></a>
                </div>
            </div>
        </div>
    </section>
      </>
    );
  }
  
  export default Cover;
  