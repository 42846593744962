import '../../assets/css/privacy.css';

export default function PrivacyPolicyHero() {
  return (
    <section className="privacy-banner">
      <div className="container">
        <h1>Privacy Policy</h1>
      </div>
    </section>
  );
}
