export default function TermsAndConditions() {
  return (
    <div className="container">
      <div className="privacy-section">
        <div className="privacy-content counter-document">
          <p>
            These Terms and Conditions (“Terms”) govern your use of services at
            www.casava.com (“Website”) including any affiliated websites and
            services operated by Casava Microinsurance Limited (hereinafter
            referred to as “Casava”, “Us”, “We”, or “Our”). Please read these
            Terms before using or continuing to use this Website. Do not agree
            to the Terms unless you fully understand and accept each provision.
            By using or continuing to use the Website, you acknowledge and
            represent that you understand, agree and accept all terms and
            conditions contained in these Terms and agree to comply and be bound
            by same.
          </p>
          <p>
            If you do not agree to these Terms, please do not continue to use or
            access the Website. We recommend that you print a copy of these
            terms for future reference. We amend these terms from time to time.
            Every time you wish to use our Website, please check these Terms to
            ensure you understand and accept the terms that apply at that time.
          </p>
          <p>
            We reserve the right to modify the terms and conditions of our
            relationship at any time, but we will always notify you before
            implementing any changes. All updates will be clearly outlined on
            our website, and you will have access to the latest version of our
            terms at any time.
          </p>
          <br />
          <br />

          <h6>The Website</h6>
          <p>
            To the extent that anything on or associated with the Website is in
            conflict or inconsistent with these Terms, the Terms shall prevail.
            Any express waiver or failure to promptly exercise any right under
            the Terms will not create a continuing waiver or any expectation of
            non-enforcement. If any provision of the Terms is held invalid by
            any law or regulation of any government, or by any court or
            arbitrator, such provision will be replaced with a new provision
            that reflects and accomplishes Our original business purpose, such
            new provision to immediately become effective, and the other
            provisions of the Terms, not replaced will remain in full force and
            effect.
          </p>

          <br />
          <br />

          <h6>Services</h6>
          <p>
            The services offered by Casava under this Terms include various
            products and services such as health insurance, health cash,
            Businessgro, and credit life products (“Services”).
          </p>

          <br />
          <br />
          <h6>Account/Registration</h6>
          <p>
            These terms apply when you decide to open an account with Casava and
            subscribe to any of our insurance policies.
          </p>

          <br />
          <br />
          <h6>Refund</h6>
          <p>
            Refund requests from an insured will only be entertained if the
            insured is still on cover or if there was an overpayment.
          </p>

          <ol>
            <li>
              You can only be refunded for your premium when your policy is
              still active. The period of cover utilized will be . calculated
              and deducted from the overall premium amount paid and the balance
              will be refunded to you. You will not be refunded your premium
              when the period of coverage has expired or has been used.
            </li>

            <li>
              In cases where there is an overpayment of your premium, due to a
              technical glitch or human error, you may opt to transfer the
              premium to the following month or request for a refund of the
              excess amount which will immediately be refunded to your account.
            </li>

            <li>
              When you get charged fifty naira (₦50) during your onboarding as a
              card verification fee, the payment is immediately reversed to your
              bank account.
            </li>

            <li>
              When you register as a new customer and have not utilized any of
              the services on your policy, requesting an account closure within
              15 days entitles you to a full refund of your premium.
            </li>
          </ol>

          <p>
            Please note that all refunds will be processed through the same
            channel used for the original payment and are contingent upon
            successful verification checks to prevent financial fraud.
          </p>

          <br />
          <br />

          <h6>Account Closure</h6>
          <p>
            You can close your account at any time and at no cost. If you'd like
            to do this, please get in touch with us. You will, however, remain
            liable for any obligations related to your account with us. Once the
            account is closed, you will not be able to access any Casava
            services. We reserve the right to close or suspend access to your
            account, if:{' '}
          </p>
          <ol>
            <li>
              The information we obtain from you does not comply with regulatory
              requirements
            </li>
            <li>
              You do not meet, or are in breach of, the terms and conditions
              contained herein
            </li>
            <li>You create risk or possible legal exposure to us</li>
            <li>We are required to do so by law</li>
            <li>
              There is a report of, or our investigations reveal that you have
              engaged in, fraudulent or suspicious activity with your Casava
              account.
            </li>
          </ol>

          <p>Please note that the list above is not exhaustive.</p>

          <p>
            Casava is not obliged to refund premiums paid overtime if your
            account is canceled or suspended, except in cases where the premium
            paid for a specific period is active and the stipulated conditions
            for refund apply.
          </p>

          <br />
          <br />

          <h6>Chargeback</h6>
          <p>
            When you file a chargeback, which is a request for a reversal due to
            service not rendered, with your bank, the service provider's
            notification will be investigated and if service has indeed not been
            rendered to you for the payment you made, a reversal will be
            approved.
          </p>

          <p>
            The payment will be made to your bank account within 3 to 7 working
            days, this may vary depending on your bank.
          </p>

          <br />
          <br />

          <h6>Reimbursements</h6>
          <p>
            A reimbursement is when you pay out of pocket for health care at the
            hospital while your Casava health insurance is still active, and
            then you request for a refund of the payment you made.
          </p>

          <p>
            We strongly recommend that you do not pay out of pocket except for
            any service at the hospital where it has been confirmed by a
            customer service representative that the benefit is not covered on
            your plan. You may reach out to us via any of our communication
            channels to confirm your coverage.
          </p>

          <p>
            You are not eligible for reimbursements for payments made out of
            pocket for micro-service plans on Casava.
          </p>

          <br />
          <br />

          <h6>Intellectual Property</h6>
          <p>
            All rights, title, and interest, including all Intellectual Property
            rights, in and to our Website and all components thereof and
            information therein, our brand features, and any and all materials
            and information that may be made available to you by or on behalf of
            Casava or its representatives in the performance of our obligations
            under these Terms vest exclusively in Casava, its affiliates or
            third party licensors. You are only granted a limited right to use
            the Website and any such aforementioned materials and information
            for the sole purpose of accessing our services in accordance with
            these Terms.
          </p>

          <p>
            You will notify us if you become aware of any infringement or
            misappropriation of any of our Intellectual Property rights in the
            Website, or our brand features and will fully cooperate with us, at
            our cost, in any action taken by us to enforce our Intellectual
            Property rights.
          </p>

          <br />
          <br />

          <h6>Data Protection and Security</h6>
          <p>
            We will comply with the Nigeria Data Protection Act 2023 and the
            Nigeria Data Protection Regulation 2019 in processing the Personal
            Information that you share with us on our Website. We shall ensure
            that there is no unauthorised access, dissemination, or misuse of
            your personal information on our Website. In the event of an actual
            or suspected breach of your personal information, we will notify of
            such breach and of steps taken to investigate and mitigate any
            losses, or damages arising from such breach.
          </p>

          <br />
          <br />

          <h6>Malware & Viruses</h6>
          <p>
            We do not guarantee that our Website will be secure or free from
            bugs, viruses or similar malware. <br />
            You are responsible for configuring your store settings and platform
            to access the Website. You should use your own malware protection
            software. <br />
            You must not misuse the Website by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to the Website, the server on which the Website is stored or
            any server, computer or database connected to the Website. You must
            not attack the Website via a denial-of-service attack or a
            distributed denial-of service attack. By breaching this provision,
            you would commit a criminal offence under the Cybercrime Act 2015.
            We will report any such breach to the relevant law enforcement
            authorities and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our website will cease immediately and you shall
            have no claim for refunds of any monies which have been paid towards
            the use of the services available on the Website.
          </p>

          <br />
          <br />

          <h6>Privacy Policy</h6>
          <p>
            Your privacy is of utmost important to us. Please read our Privacy
            Policy which governs your use of the Website for more information.
            By using the Website, you agree that the terms of this Policy are
            reasonable and satisfactory to you. You consent to the use of your
            personal information by Casava in accordance with the terms of this
            Privacy Policy.
          </p>

          <br />
          <br />

          <h6>Disclaimer of Warranty</h6>
          <p>
            You understand and agree that your use of the Website is at your
            sole risk. The Website including the information, services, and
            content is provided on an “as is”, “as available”, and “with all
            fault” basis. Casava disclaims all express or implied conditions,
            representations, and warranties of any kind, including any implied
            warranty or condition of merchantability, satisfactory quality, or
            fitness for a particular purpose. Casava makes no representations,
            warranties, conditions or guarantees as to the usefulness, quality,
            suitability, truth, accuracy, or completeness of the Website.
          </p>

          <p>
            You hereby acknowledge that the data and any materials provided or
            licensed hereunder are provided without warranties or
            representations, and Casava shall not accept any liability in
            connection with their use, storage and disposal by you. Except as
            expressly set forth in this Terms, Casava makes no representation or
            warranty to you of any kind, express or implied, including any
            warranty of non-infringement, merchantability or fitness for a
            particular purpose or that any technology will be free from
            infringement of patents or proprietary rights of third parties, or
            that no third parties are in any way infringing patent rights.
            Without limiting the generality of the foregoing, you understand and
            acknowledge that no representation or warranty is made regarding the
            utility of any information, materials or technology licensed
            hereunder.
          </p>

          <br />
          <br />

          <h6>Governing Law and Jurisdiction</h6>
          <p>
            These Terms shall be governed and construed in accordance with the
            applicable laws of the Federal Republic of Nigeria.
          </p>

          <p>
            Any dispute or claims arising from this Terms shall be subject to
            the exclusive jurisdiction of a competent court in Nigeria.
          </p>

          <br />
          <br />

          <h6>Injunction</h6>
          <p>
            You agree that any material breach of the Terms will result in
            irreparable harm to Casava for which damages would be an inadequate
            remedy and, therefore, in addition to its rights and remedies
            otherwise available at law, Casava will be entitled to equitable
            relief, including both a preliminary and permanent injunction, if
            such a breach occurs. You waive any requirement for the posting of a
            bond or other security if Casava seeks such an injunction.
          </p>

          <br />
          <br />

          <h6>Entire Agreement</h6>
          <p>
            These Terms constitute the entire agreement between you and Casava
            relating to this subject matter and cancel and supersede any prior
            versions of the Terms. You may not assign or otherwise transfer the
            Terms, or any right granted hereunder. You also may be subject to
            additional terms and conditions that may apply when you use Casava's
            products or services.
          </p>
        </div>
      </div>
    </div>
  );
}
