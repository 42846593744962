import Banner from '../components/Common/Banner';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';
import TermsHero from '../components/Terms/Hero';
import TermsAndConditions from '../components/Terms/TermsAndConditions';

export default function Terms() {
  return (
    <div>
      <Header />
      <TermsHero />
      <TermsAndConditions />
      <Banner />
      <Footer />
    </div>
  );
}
