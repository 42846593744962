import Banner from '../components/Common/Banner';
import Footer from '../components/Common/Footer';
import Header from '../components/Common/Header';
import PrivacyPolicyHero from '../components/Privacy/Hero';
import PrivacyPolicies from '../components/Privacy/Policies';

export default function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <PrivacyPolicyHero />
      <PrivacyPolicies />
      <Banner />
      <Footer />
    </div>
  );
}
