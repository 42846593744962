
function Cover() {
    return (
      <>
        {/* cover section  */}
        <section className="hero-section md:bg-pink-300 business-page">
            <div className="container">
                <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
                    <div>
                        <h1>We want to see <br /> businesses <br /> prosper</h1>
                        <p className="text-lg md:text-xl mr-0 md:mr-10">Give your customers and employees insurance <br /> coverage in real-time</p>
                        <a href="" className="button button--white mt-6"><span>Get In Touch</span></a>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default Cover;
  