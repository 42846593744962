
import what_you_get from '../../assets/what-you-get.svg';

function SplitBannerHealthCash() {
    return (
      <>
         
        {/* steps claim section  */}
        <section className="what-you-get-section">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto">
                        <h1 className="mb-6">It's a relief! <br /> With cash back in your pocket</h1>
                        <p className="text-xl">Don't worry, you'll get the exact amount you spent at the hospital.</p>
                    </div>
                    <div className="image-layout order-first lg:order-last"><img src={what_you_get} alt="casava ai claims illustration image" /></div>
                </div>
            </div>
        </section>

      </>
    );
  }
  
  export default SplitBannerHealthCash;
  