import testimonial1 from '../../assets/photo-testimonial-1.jpg';
import testimonial2 from '../../assets/photo-testimonial-2.jpg';
import testimonial3 from '../../assets/photo-testimonial-3.jpg';
import testimonial4 from '../../assets/photo-testimonial-4.jpg';
import { useState } from 'react';
function Testimonials() {
  const [selectedKey, setselectedKey] = useState(0);

  let testimonialsData = [
    {
      key: 0,
      comment: 'Simplest onboarding experience I have ever experienced.',
      img: testimonial1,
      status: 'active',
      user: 'Benson Iritoju',
    },
    {
      key: 1,
      comment:
        'Casava has very affordable rates with top-notch customer experience.',
      img: testimonial2,
      status: 'inactive',
      user: 'Sarah Oriaku',
    },
    {
      key: 2,
      comment:
        'I got initiated into the Casava clan through their income protection product. I like that they offer more than just the regular health insurance options.',
      img: testimonial3,
      status: 'inactive',
      user: 'Ben Akinlabi',
    },
    {
      key: 3,
      comment:
        'Casava shows that insurance can be done differently. Who knew I could protect my income for the future? Awesome job, guys.',
      img: testimonial4,
      status: 'inactive',
      user: 'Ayodeji George',
    },
  ];

  const showComment = (selectedElm) => {
    testimonialsData.forEach((element) => {
      if (element.img == selectedElm.img) {
        element.status = 'active';
        setselectedKey(element.key);
      } else {
        element.status = 'inactive';
      }
    });
  };

  return (
    <>
      {/* testimonials section */}
      <section className="testimonials-section">
        <div className="container">
          <div className="flex items-center">
            <h1 className="w-full lg:w-2/3">
              100,000+ Nigerians <br /> enjoy Casava.
            </h1>
          </div>
          <div className="grid grid-cols-1 gap-10 items-center mt-12 lg:mt-32 lg:grid-cols-2 lg:gap-20">
            <div className="testimonial-card__text">
              <h3 className="font-sans font-normal text-2xl md:text-4xl">
                {testimonialsData[selectedKey].comment}
              </h3>
              <p className="mt-8">{testimonialsData[selectedKey].user}</p>
            </div>
            <div className="testimonial-photos">
              {testimonialsData.map((element, index) => {
                return (
                  <div
                    className={
                      element.key == selectedKey
                        ? 'active'
                        : '' + ' testimonial__photo'
                    }
                    onClick={() => showComment(element)}
                  >
                    <img
                      loading="lazy"
                      src={element.img}
                      alt="testimonial photo"
                      width={289}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonials;
