
import haruna_ai_illo from '../../assets/haruna-ai-illo.png';

function SplitBannerClaims() {
    return (
      <>
         
        {/* steps claim section  */}
        <section className="fature-section light-pink--background">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 lg:grid-cols-2 lg:gap-20 items-center">
                    <div className="flex flex-col lg:ml-auto">
                        <h1 className="mb-6">Meet Haruna, your claims partner</h1>
                        <p className="text-xl">Haruna makes the claims process so much easier. Make a claim anytime, and receive a response in minutes</p>
                        <a href="https://forms.gle/Wi9Zf2ocHkz4JLAQA" target="_blank" className="button button--pink mt-10 self-start"><span>Send a message</span></a>
                    </div>
                    <div className="image-layout order-first lg:order-last"><img src={haruna_ai_illo} alt="casava ai claims illustration image" /></div>
                </div>
            </div>
        </section>

      </>
    );
  }
  
  export default SplitBannerClaims;
  