import Header from "../components/Common/Header";
import Faq from "../components/Common/Faq";
import Banner from "../components/Common/Banner";
import Footer from "../components/Common/Footer";
import faqData from '../config/faq.json';
import Cover from "../components/Claims/Cover";
import ClaimProcess from "../components/Claims/ClaimProcess";
import SplitBannerClaims from "../components/Common/SplitBannerClaims";

function Claims() {
  let index  = Math.ceil(faqData["healthCashData"]['rows'].length/2);

  let rows = faqData["healthCashData"].rows;
  let data1 = {title:'',rows:[]};
  data1.rows = rows.slice(0,index);

  let data2 = {title:'',rows:[]};
  data2.rows = rows.slice(index,faqData["healthCashData"]['rows'].length);

  return (
    <div>
        <Header></Header>
        <Cover></Cover>
        <ClaimProcess></ClaimProcess>
        <SplitBannerClaims></SplitBannerClaims>
        <Faq data1={data1} data2={data2}></Faq>
        <Banner></Banner>
        <Footer></Footer>
    </div>
  );
}

export default Claims;
