import React from "react";


function Insuretech() {

  return (
    <div>
      <iframe style={{ position: 'fixed', width: "100%", height: '100vh' }} width={'100%'} height={'100'} src="https://casava-insuretechconnect.framer.website/"></iframe>
    </div>
  );
}

export default Insuretech;
