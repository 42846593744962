import '../../assets/css/privacy.css';

export default function TermsHero() {
  return (
    <section className="privacy-banner">
      <div className="container">
        <h1>Terms and Conditions</h1>
      </div>
    </section>
  );
}
