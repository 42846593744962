import icon_green_check from '../../assets/green-check.svg';


function HealthSteps() {
    return (
      <>
        {/* health steps section */}
        <section className="health-steps-section">
            <div className="container">
                <div className="grid gap-10 grid-cols-1 md:grid-cols-2 items-center md:gap-12">
                    <div className="flex flex-col">
                        <h2 className="mb-8">
                            Smooth experience, <br />
                            better healthcare. 
                        </h2>
                        <p className="text-xl">We've designed a product that covers your health needs, starting from as low as N1,100</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="step-item">
                            <img src={icon_green_check} />
                            <div className="ml-8">
                                <h5>Subscribe</h5>
                                <p>Choose a health plan that fits your budget</p>
                            </div>
                        </div>
                        <div className="step-item">
                            <img src={icon_green_check} />
                            <div className="ml-8">
                                <h5>Choose your preferred hospital</h5>
                                <p>Select a hospital closest to you from our list of providers</p>
                            </div>
                        </div>
                        <div className="step-item">
                            <img src={icon_green_check} />  
                            <div className="ml-8">
                                <h5>Get healthcare</h5>
                                <p>Speak with a doctor, visit your preferred hospital and access other health services</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
  }
  
  export default HealthSteps;
  