// import Swiper JS
import { Swiper, SwiperSlide } from "swiper/react";
// import Swiper styles
import 'swiper/css';
import { Pagination } from "swiper";

function Carousel() {
const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
     <Swiper
        // pagination={pagination}
        modules={[Pagination]}
         pagination={{
            clickable: true,
            pagination
            }}
        className="mySwiper"
      >
        <SwiperSlide>
            <div className="slide-1">
                <div className="card-about-share">
                    <strong>We want to help people thrive and <br/> prosper by making insurance an <br/> enjoyable experience. <br/><br/></strong>
                    <p>- Ejiro <br/>
                        <span className="opacioty-half">Product Manager at Casava</span>
                    </p>
                </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
            <div className="slide-2">
                <div className="card-about-share">
                    <strong>We are pioneering Income <br/> Protection, a product that provides <br/> financial benefits to workers if they <br/> lose their job.</strong>
                    <p>- Ayodile <br/>
                        <span className="opacioty-half">Claims and Compliance Manager at Casava</span>
                    </p>
                </div>
            </div>
        </SwiperSlide>
      </Swiper>
  );
}

  export default Carousel;
  