import FaqComponent from 'react-faq-component';

function BusinessFaq(props) {
    



    const data1 = props.data1 ? props.data1 : {};
    const data2 = props.data2 ? props.data2 : [];

    return (
      <>
        {/* faq section */}
        <div>
            <section className="faq-section">
                <div className="container">
                       
                        <div className="flex justify-items-center justify-center">
                            <h1 className="w-full lg:w-2/3 lg:text-center">
                                You asked, We've<br />
                                answered.
                            </h1>
                        </div>

                       <FaqComponent
                            data={data1}
                        /> 


                        <FaqComponent
                            data={data2}
                        />  
                       
                </div>
            </section>
        </div>
      </>
    );
  }
  
  export default BusinessFaq;
  