export default function PrivacyPolicies() {
  return (
    <div className="container">
      <div className="privacy-section">
        <div className="privacy-content">
          <p>
            At Casava Microinsurance Limited (“Casava”, “we”, or “us”, or
            “our”), we are committed to protecting the privacy and security of
            personal information supplied by employees, customers, vendors and
            other interested persons (“you” or “your”). This Privacy Policy
            ('Policy”) outlines how we collect, use, disclose, and protect your
            information when you visit our website. It also explains your rights
            and choices concerning your personal information.{' '}
          </p>
          <p>
            For the purpose of this Policy, “Website” refers to Casava's website
            which can be accessed at www.casava.com, “Service(s) includes but is
            not limited to Casava's health insurance, health cash, businessgro,
            credit life products accessed via the Website, and any other
            platform Casava may use. By accessing our Website and all affiliated
            mobile applications owned and operated by us, you agree and consent
            to our terms and practices, and to the collection, use, storage and
            sharing of your personal information as described in this Policy.{' '}
          </p>
          <p>
            Please note that the collection and processing of your personal
            information is in accordance with the Nigeria Data Protection Act,
            2023 and other data protection regulations in force in Nigeria.
          </p>
          <br />
          <br />
          <h6>Role Definitions</h6>
          <p>
            For the purpose of this Policy, the following roles are defined:
          </p>
          <p>
            Data Subject: is an identifiable person; one who can be identified
            directly or indirectly, in particular by reference to an
            identification number or to one or more factors specific to his
            physical, physiological, mental, economic, cultural, or social
            identity and includes Casava's clients, customers, business
            partners, and employees.
          </p>
          <p>
            Data Administrator: means an individual or group that handles data
            processing. Casava Microinsurance Limited is the Data Administrator
            for this Policy.
          </p>
          <p>
            Data Controller: means a person who, either alone, jointly with
            other persons, or in common with other persons or as a statutory
            body, determines the purposes for and the manner in which personal
            data is processed or is to be processed. For this Policy, Casava
            Microinsurance Limited is the Data Controller.
          </p>
          <p>
            Data Protection Officer: Casava has appointed a Data Protection
            Officer to ensure that the strategy and implementation of data
            protection requirements are in compliance with the data protection
            policy and the relevant extant laws.{' '}
          </p>
          <p>
            The responsibilities of the Data Administrator, Data Controller, and
            Data Protection Officer are clearly outlined in the Nigeria Data
            Protection Act (2023).{' '}
          </p>
          <br />
          <br />
          <h6>What Information Do We Collect?</h6>
          <p>
            Only minimum information required to meet the purposes mentioned in
            this Policy shall be collected from the data subject. Neither Casava
            nor its representatives shall be responsible for the authenticity of
            such information provided by the data subject. As normal business
            practice, Casava may collect information in order to enable secure
            online authentication, interaction, and transaction with natural
            persons. At Casava, we collect various types of information to
            effectively provide you with our Services. The information we
            collect falls into the following categories:
          </p>
          <br />
          <br />
          <h6>A. Information You Give Us:</h6>
          <p>
            Personal Information:
            <br />
            When you visit our Website, we may collect personal information that
            could potentially identify an individual. Such information includes,
            but is not limited to name, age, email addresses, phone numbers,
            etc.
          </p>
          <p>
            Other sensitive personal information: these are defined as
            information that if lost, compromised, or disclosed could result in
            substantial harm, embarrassment, inconvenience, or unfairness to an
            individual. Such information includes but not limited to marital
            status and religious affiliation.
          </p>
          <p>
            Financial information:
            <br />
            Casava may process information related to payments you make or
            receive in the context of an insurance policy. This includes
            information such as Bank Verification Number (BVN) and information
            obtained from credit reference agencies.
          </p>
          <p>
            Means of identification:
            <br />
            We may collect National Identity Card Number (NIN), International
            Passport details, Drivers' License, Voter's card details, etc.
          </p>
          <p>
            Health information:
            <br />
            We may collect information such as smoker status or medical-related
            issues relevant to a policy the data subject holds or a claim the
            data subject has.
          </p>
          <p>
            By providing your personal information and any other kind of
            information to us, you expressly agree to the collection, use,
            storage and disclosure of such information as described in this
            Policy.
          </p>
          <br />
          <br />
          <h6>B. Information We Collect About You:</h6>
          <p>
            We may automatically collect information about your interactions
            with our Website. This information includes, your
          </p>
          <p>
            Log Data: this may include your device's Internet Protocol (IP)
            address, browser type, page visited, time zone settings and so on.
          </p>
          <p>
            Device information: this includes device-specific information such
            as hardware model, operating system version, unique device
            identifiers and so on.
          </p>
          <p>
            Usage: this may include the full Uniform Resource Locators (URL)
            clickstream to, through and from our Website (including date and
            time), page response times, download errors, length of visit to
            certain pages, page interaction (such as scrolling, clicks and
            mouse-overs), and methods used to browse away from the page and any
            phone number to call our customer service number.
          </p>
          <br />
          <br />
          <h6>
            C. Information Collected Through Cookies and Other Similar
            Technologies:
          </h6>
          <p>
            To enhance your experience on our Website, Casava employs cookies
            and similar tracking technologies. These technologies aid us in
            collecting information related to your preferences, settings, and
            other analytical data. Our use of cookies and similar technologies
            includes:
          </p>
          <p>
            Web Beacons:
            <br />
            These are electronic images used to understand how you interact with
            our Website and emails.
          </p>
          <p>
            Flash Cookies:
            <br />
            Also known as Local Shared Objects, these are used to store data for
            various purposes, such as remembering your settings.
          </p>
          <p>
            Persistent or Session Cookies: These types of cookies store
            information for varying periods. Persistent cookies remain on your
            device for a set period, while session cookies are temporary and are
            deleted once you close your browser.
          </p>
          <p>
            By accessing or using our Website, you provide your consent for
            cookies to be placed on your device in accordance with this Policy.
            If you wish to manage the receipt of cookies through our Website,
            you have the ability to control how your web browser responds to
            cookies. This can be done by adjusting your browser's privacy and
            security settings.
          </p>
          <p>
            Please note that unless you configure your browser settings to
            reject all cookies, our system may issue cookies when you access or
            use our Website. If you choose to decline all cookies, it is
            important to be aware that certain features of our Website may
            experience limited functionality or may not work at all. For
            additional general information about cookies and their functioning,
            you can visit www.allaboutcookies.org
          </p>
          <br />
          <br />
          <h6>D. Information We Receive from Others</h6>
          <p>
            In certain cases, we may receive information about you from
            third-party sources, including credit bureaus and identity
            verification services. The information collected allows us to
            process your applications, provide customer support, improve our
            Services, and comply with legal and regulatory requirements.
            Provided that in the case of data obtained from a third-party
            source, a copy of the data subject's consent has been given.
          </p>
          <br />
          <br />
          <h6>How Do We Collect Your Information?</h6>
          <p>
            In most cases, Casava collects Personal Data directly from you.
            Collection methods include:
          </p>
          <ol>
            <li>Know Your Customer (KYC) forms</li>
            <li>Electronics means (emails and apps)</li>
            <li>Claim forms</li>
            <li>
              Employee engagement personal data forms (inclusive of medical
              report)
            </li>
            <li>Forums and feedback forms</li>
            <li>Recorded telephone conversations</li>
            <li>Digital touchpoints</li>
            <li>Live chat, chatbot, and profiler</li>
            <li>Inquiry and Quote forms</li>
            <li>Surveys or votes in a poll on our website</li>
            <li>Cookies</li>
            <li>Web analytics tags</li>
          </ol>
          <br />
          <p>
            We also collect your Personal Data from other third-party sources,
            including:
          </p>
          <ol>
            <li>
              Family members in instances of incapacitation or death of the
              insured for claims payment
            </li>
            <li>Credit reference agencies</li>
            <li>
              Contractors, consultants, business partners who sell our products
              and services via their platforms
            </li>
            <li>Medical professionals and hospitals</li>
            <li>Loss adjusters, claim assessors, aggregators</li>
            <li>
              Third parties who assist us in checking that claims are eligible
              for payment.
            </li>
          </ol>
          <br />
          <br />
          <h6>How Do We Use Your Information?</h6>
          <p>
            Casava uses the personal information collected to provide you with
            our Services and to improve your overall experience on our Website.
            The information we collect in the manner described above is utilized
            for the following purposes:
          </p>
          <ul>
            <li>
              Personalization: the information we collect helps us personalise
              your experience on our Website and to provide you with relevant
              content and offers.
            </li>
            <li>
              We may use Cloud storage solutions within or outside Nigeria,
              which are chosen to ensure efficiency and improved performance
              through up-to-date technology.
            </li>
            <li>
              Communication: we may use your contact information to communicate
              with you regarding our Services, promotions, or important updates.
              This could include notifications about purchases and changes to
              our terms, conditions and policies and about special offers on
              other products and services we think you might need. Our customer
              support team may also use this information to assist you with
              inquiries and concerns.
            </li>
            <li>
              Legal and Regulatory Compliance: Where we have a legal or
              regulatory obligation to use such Personal Data, for example, when
              our regulators such as the National Insurance Commission (NAICOM),
              the Securities and Exchange Commission (SEC), the National Health
              Insurance Agency (NHIA), and our data protection regulator, the
              Nigeria Data Protection Commission (NDPC) wish us to maintain
              certain records of any dealings with you. We may collect your
              personal information to comply with local or foreign laws,
              regulations, voluntary codes, directives, judgments or court
              orders, and agreements between Casava and any authority,
              regulator, or enforcement agency.
            </li>
            <li>
              We may analyze non-personal information to understand user
              behavior, optimize our website, and improve our marketing
              strategies.
            </li>
            <li>
              We may use your personal information to establish, exercise, or
              defend our legal rights, for example, when we are faced with any
              legal claims or where we want to pursue any legal claims
              ourselves.
            </li>
            <li>
              We may use your personal information for reasons of substantial
              public interest, such as investigating fraudulent claims and
              carrying out fraud, credit and anti-money laundering checks, and
              identification checks.
            </li>
          </ul>
          <br />
          <br />
          <h6>Disclosure of your Information</h6>
          <p>
            At Casava, we value your privacy and aim to be transparent about how
            your information is shared. We will not share any of your personal
            information other than for the purposes described in this Policy. If
            we share anything outside Casava, it will be kept strictly
            confidential and will only be used for reasons that we have
            described. We may share the information we collect with various
            third parties under the circumstances outlined below:
          </p>
          <ol>
            <li>
              We may share your information with trusted third-party service
              providers who assist us in delivering our services. These service
              providers are obligated to maintain the confidentiality and
              security of your information.
            </li>
            <li>
              We may disclose your information if required by law or in response
              to a legal process, such as a court order or government request.
            </li>
            <li>
              We may disclose your information to our professional advisers:
              auditors, reinsurers, medical agencies, and legal team.
            </li>
            <li>
              We may disclose your information to Credit referencing
              organizations to obtain information to determine risk selection,
              pricing, and underwriting.
            </li>
            <li>
              We may disclose your information to fraud detection agencies and
              other parties who maintain fraud detection.
            </li>
            <li>
              We may disclose your information to financial organizations, the
              government, and its agencies.
            </li>
            <li>
              We shall request your permission to share your personal
              information for a specific purpose. We will notify you and request
              consent before you provide your personal information or before
              your personal information already provided is shared for such
              purposes.
            </li>
          </ol>
          <p>
            The above disclosures to the third party shall be made only to the
            extent necessary for the specific purpose for which the data is
            provided. The third party shall be informed of the confidential
            nature of such information and shall be directed to keep the data
            subject's information strictly confidential.
          </p>

          <br />
          <br />

          <h6>Your Rights</h6>
          <p>
            Data subjects have the following rights in respect of Casava's use
            of their personal information: These rights include:
          </p>

          <p>
            Right to access: The data subject has a right to a copy of their
            personal information as maintained by us.
          </p>

          <p>
            Right to rectify: Casava takes due care to ensure that the personal
            information we maintain about data subjects is accurate and
            complete. However, if a data subject believes the information is
            inaccurate or incomplete, such data subject has the right to request
            rectification.
          </p>

          <p>
            Right to erase: Under certain circumstances, a data subject may ask
            that we erase their personal information. For instance, where the
            personal information collected is no longer necessary for the
            original purpose or where consent is withdrawn. However, this will
            need to be balanced against other factors, such as the type of
            personal information obtained, the original reason for collection,
            archiving purposes in the public interest, scientific or historical
            research purposes, or statistical purposes, and our continuous
            assessment of risk relating to the data.
          </p>

          <p>
            Right to restriction of processing: Under certain circumstances, but
            subject to regulatory requirements, a data subject may be entitled
            to instruct us to stop using his/her personal data. This is
            applicable where a data subject contests the accuracy of personal
            information held by the data controller. The data controller no
            longer requires personal data, but the data subject for the
            establishment, exercise, or defense of legal claims requires the
            data
          </p>

          <p>
            Right to data portability: Under certain circumstances, data
            subjects have the right to ask Casava to transfer any personal
            information that they have provided to Casava to another third
            party. Once transferred, the other party will be responsible for
            safeguarding such personnel.
          </p>

          <p>
            Right to object to marketing: The Data Subject can object to the
            processing of his/her personal data for third-party marketing.
          </p>

          <p>
            Right to lodge a complaint: Casava's data subject has the right to
            lodge complaints in the event that there is an objection to the
            manner in which Casava is using personal information. Such
            complaints can be communicated using the contact details provided in
            our policy documentation. In certain cases, Casava may be unable to
            comply with the data subject's requests for reasons such as our
            obligations to comply with other legal or regulatory requirements.
          </p>

          <br />
          <br />

          <h6>Security Measures</h6>
          <p>
            We use industry-standard encryption protocols to protect the
            transmission of sensitive information between your browser and our
            servers. We take reasonable steps to ensure that the personal
            information we collect is accurate, complete, and up-to-date. Upon
            receiving your information, we employ stringent procedures and
            security features to mitigate the risk of unauthorized access.
          </p>

          <p>
            However, in the event of a breach of security leading to the
            accidental or unlawful destruction, loss, alteration, unauthorized
            disclosure of, or access to, personal information, Casava shall,
            within 72 (seventy-two) hours of having knowledge of such breach,
            report the details of the breach to NDPC. Furthermore, Casava shall
            having knowledge of the occurrence of such breach, take steps to
            inform the Data Subject of the breach incident, the risk to the
            rights and freedoms of the Data Subject resulting from such breach,
            and any course of action to remedy said breach.
          </p>
          <br />
          <br />

          <h6>How long will your Personal Information be kept?</h6>
          <p>
            At Casava, we retain your information only for as long as necessary
            to fulfil the purposes outlined in this Policy and to comply with
            legal and regulatory requirements. The retention periods for
            different categories of data may vary based on factors such as the
            amount, nature and sensitivity of the personal information, the
            potential risk of harm from unathourised disclosure of your personal
            information and the applicable legal, regulatory, tax, accounting
            and other requirements. If you wish to inquire about the specific
            retention period for your data or request its deletion, please refer
            to the “Contact Information” section of this Policy.
          </p>

          <p>
            Please note that even after your personal information is removed
            from our active systems, residual copies may remain in backup
            archives for a limited period. Such copies will be safeguarded in
            line with our data retention and security policies.
          </p>

          <br />
          <br />

          <h6>How do we store your Personal Information</h6>
          <p>
            The personal information collected from you is stored on our secure
            servers. In cases where we have provided you with a password to
            access specific areas of our Website, it is your responsibility to
            keep this password confidential. We kindly request that you refrain
            from sharing your password with anyone.
          </p>

          <p>
            Please be aware that while we take every precaution to safeguard
            your personal information, the transmission of information over the
            internet is not entirely immune to security risk. Despite our
            continuous efforts to ensure the protection of your personal
            information, we cannot provide an absolute guarantee of 100%
            security for data transmitted to our Website. Any transmission of
            data is undertaken at your own risk.
          </p>

          <br />
          <br />

          <h6>International Data Transfers</h6>
          <p>
            The information we collect may be stored, processed and transferred
            among the countries where we operate. This is done to enable us to
            use the collected information in accordance with the policies
            outlined here. Any such information transfers to other countries
            will adhere to Nigeria’s data protection law and regulations.
          </p>

          <p>
            It is important to note that we may transfer your personal
            information outside of Nigeria under the following circumstances:
          </p>

          <ul>
            <li>With your explicit consent.</li>

            <li>
              When the transfer is necessary to fulfil a contract between you
              and Casava, or for the implementation of pre-contractual measures
              at your request.
            </li>

            <li>
              When the transfer is necessary for the execution or performance of
              a contract that is in your interest, between Casava and another
              individual or legal entity.
            </li>

            <li>
              When the transfer is essential for important reasons related to
              public interest.
            </li>

            <li>
              When the transfer is necessary for establishing, exercising, or
              defending legal claims.
            </li>

            <li>
              When the transfer is required to safeguard your vital interests or
              the vital interests of others, especially in cases where you are
              incapable of providing consent due to physical or legal reasons.
            </li>
          </ul>

          <p>
            It is important to acknowledge that when information is transferred
            outside of Nigeria, certain data protection principles in the
            receiving country might differ. By consenting to the processing of
            your personal information, you are also consenting to the transfer
            of that data outside of Nigeria.
          </p>

          <br />
          <br />

          <h6>How do you consent to the use of your Personal Information?</h6>
          <p>
            By using our Website and Services, you acknowledge and provide your
            explicit consent to the collection, processing and use of your
            personal information as outlined in this Policy. You agree that your
            personal information may be collected, stored and processed for the
            purposes mentioned in this Policy.
          </p>

          <p>
            You understand that your personal information may be used to
            facilitate transactions, provide customer support, enhance your
            experience, and comply with legal and regulatory obligations. This
            may involve sharing your information with trusted parties, as
            detailed in this Policy.
          </p>

          <p>
            You have the right to withdraw your consent at any time. However,
            please note that withdrawing consent may impact your ability to
            access certain features or Services. By continuing to use our
            Website, you indicate that you have read, understood, and agreed to
            the terms of this Policy and provide your consent for collection,
            processing and use of your personal information as described in this
            Policy.
          </p>

          <br />
          <br />

          <h6>Remedies</h6>
          <p>
            Casava and you will be entitled to enforce its rights in the event
            of a breach of the terms of this Policy, to recover damages caused
            by any breach of the provisions herein and to exercise all other
            rights existing under applicable laws. Any claim or dispute arising
            in relation to this Policy shall be subject to the jurisdiction of
            the courts of the Federal Republic of Nigeria. We shall not be
            liable for any breach where a claim in respect of such breach is not
            brought within one month of the date that such breach arose.
          </p>

          <br />
          <br />

          <h6>Changes to This Policy:</h6>
          <p>
            We may update this Policy from time to time to reflect changes in
            our practices and to ensure compliance with any changes or
            amendments to relevant legislation. We will notify you of any
            changes by posting the updated Policy on this page. We will let you
            know via email and/or a prominent notice on our Website, prior to
            the change becoming effective and update the “effective date” in
            this Policy. We encourage you to review this page periodically for
            the latest information on our privacy practices. Changes to this
            Policy are effective when they are posted on our Website or provided
            to you.
          </p>

          <br />
          <br />

          <h6>Contact Information:</h6>
          <p>
            If you have any questions, concerns, or requests regarding this
            Policy, the handling of your data, or if you wish to exercise your
            rights as described herein, please contact us at hello@casava.com
            Our dedicated team will address your inquiries and requests
            promptly. Please provide sufficient information to help us identify
            you in our records and respond effectively.
          </p>

          <p>
            Thank you for choosing Casava Microinsurance Limited. Your privacy
            is important to us.
          </p>

          <p>
            Casava Microinsurance Limited
            <br />
            17 Olosa Street, Victoria Island
            <br />
            Victoria Island, Lagos 101241
            <br />
            hello@casava.com
          </p>
        </div>
      </div>
    </div>
  );
}
